<template>
  <module-template
    id="scrolltainer"
    title
    :canEdit="$store.state.runRenamePermission"
    canList
    :canCopy="$store.state.runCreatePermission"
    :canDelete="$store.state.runDeletePermission && !run?.lockUser"
    :canExport="canExportOlp"
    @copyItem="copyRun"
    @deleteItem="deleteRun"
    @editItem="editRun"
    @list="listRuns"
    @export="onExportOlp"
  >
    <template #title>
      <v-row dense class="px-1" align="center">
        {{ $t("global.run.run") }}
        <v-icon color="white" class="px-3" size="9">pic-arrow-right</v-icon>
        <div class="mr-2">
          {{ "#" + run?.id }}
        </div>
        <v-text-field dark v-model="editingName" type="text" v-if="showEdit">
          <template #append-outer
            ><v-btn @click="onEditConfirm" class="mr-1" dark fab outlined small
              ><v-icon>pic-check</v-icon></v-btn
            >
            <v-btn @click="showEdit = false" dark fab outlined small
              ><v-icon>pic-close</v-icon></v-btn
            >
          </template></v-text-field
        >
        <div v-else>{{ run?.title }}</div>
      </v-row>
    </template>
    <v-overlay absolute :value="run?.status === 'INIT'">
      <v-chip color="blue" dark>{{
        $t("global.run.updating-dependencies")
      }}</v-chip>
    </v-overlay>
    <v-overlay
      absolute
      :value="
        run.status === 'READY_TO_START' &&
        run.requestedStatus === 'RUN_REQUESTED'
      "
    >
      <v-chip color="blue" dark>{{ $t("global.run.starting") }}</v-chip>
    </v-overlay>

    <v-row dense>
      <!-- Left Card -->
      <v-col>
        <basic-card-horizontal
          :headers="cardLeftHeaders"
          :item="cardLeftItem"
          min-width="600"
        >
          <template #[`item.bpInstance`]="{ item }">
            <span v-if="item.bpInstance">
              <v-chip class="mb-1" color="orange" dark small>
                {{ item.bpInstance.split(":")[0] }}
              </v-chip>
              <router-link :to="bpRoute">
                {{ item.bpInstance.split(":")[1] }}</router-link
              >
              <v-tooltip v-if="run?.lockUser" top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="ml-1"
                    >pic-lock-fill</v-icon
                  >
                </template>
                <span>{{ lockMessage }}</span>
              </v-tooltip>
            </span>
          </template>
          <template #[`item.task`]="{ item }">
            <router-link :to="taskRoute">{{ item.task }}</router-link>
          </template>
        </basic-card-horizontal>
      </v-col>

      <!-- Middle Card -->
      <v-col>
        <basic-card-horizontal
          :headers="cardMiddleHeaders"
          :item="cardMiddleItem"
          class="mb-2"
          min-width="600"
        >
          <template #[`item.env`]="{ item }">
            <router-link :to="envRoute">
              <file-env-chip :color="item.envColor">
                {{ item.env }}
              </file-env-chip>
            </router-link>
          </template>

          <template #[`item.status`]="{ item }">
            <v-row v-if="item.isRunning">
              <v-col>
                <v-progress-linear
                  rounded
                  height="18"
                  :value="item.progress"
                  color="light-blue"
                >
                </v-progress-linear>
              </v-col>
              <v-col cols="3">
                {{ item.progressText }}
              </v-col>
            </v-row>
            <span v-else>{{ item.translatedStatus }} </span>
          </template>
          <template #[`item.createdByName`]="{ item }">
            <router-link :to="createdByRoute">{{
              item.createdByName
            }}</router-link>
          </template>
        </basic-card-horizontal>

        <!-- Right Card -->
        <basic-card-horizontal
          :headers="cardRightHeaders"
          :item="cardRightItem"
          min-width="600"
        />
      </v-col>
    </v-row>

    <!-- Action launch / cancel -->
    <v-row class="py-3" dense v-if="!run?.lockUser">
      <v-col cols="auto">
        <v-btn v-if="canStart" @click="onStartRun" color="primary">
          <v-icon left>pic-play-fill</v-icon>
          {{ $t("global.run.start-run") }}</v-btn
        >
      </v-col>

      <v-col cols="auto">
        <v-btn v-if="canCancel" @click="onCancelRun" color="error">
          <v-icon left>pic-close</v-icon>
          {{ $t("global.action.cancel") }}</v-btn
        >
      </v-col>
    </v-row>

    <!-- Run details -->
    <v-row dense>
      <v-col cols="12">
        <run-details v-if="run" :run="run" />
      </v-col>
    </v-row>
    <a ref="downloadComponent" download target="_blank" />
  </module-template>
</template>

<script>
import { get, put, post, remove } from "@/model/api";
import { baseUrl } from "@/model/http-common";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";

import RunDetails from "@/components/run/RunDetails";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FileEnvChip from "@/components/FileEnvChip";

export default {
  name: "SimulationRun",

  components: {
    BasicCardHorizontal,
    ModuleTemplate,
    RunDetails,
    FileEnvChip,
  },

  data() {
    return {
      cardLeftHeaders: [
        { text: this.$t("global.modules.module"), value: "module" },
        { text: this.$t("global.run.simulation"), value: "simulation" },
        {
          text: this.$t("global.business-process.task"),
          value: "task",
          class: "ooliba-font-color-blue",
        },
        {
          text: this.$t("global.business-process.business-process"),
          value: "bpInstance",
          class: "ooliba-font-color-blue",
        },
      ],

      cardMiddleHeaders: [
        { text: this.$t("global.concepts.created-on"), value: "dateCreated" },
        { text: this.$t("global.environment.environment"), value: "env" },
        { text: this.$t("global.run.run-time"), value: "runtime" },
        { text: this.$t("global.concepts.status"), value: "status" },
        {
          text: this.$t("global.administration.owner"),
          value: "createdByName",
          class: "ooliba-font-color-blue",
        },
      ],

      run: {},

      timerId: undefined,

      showEdit: false,
      editingName: undefined,

      deleting: false,
    };
  },

  computed: {
    lockMessage() {
      const parsedDate = new Date(this.run?.lockDate).toLocaleString();

      return this.$t("global.business-process.lock-message", [
        parsedDate,
        this.run?.lockUser,
      ]);
    },

    taskRoute() {
      if (!this.run?.taskId) {
        return {};
      }
      return {
        name: "Process task",
        params: { taskId: this.run.taskId },
      };
    },

    bpRoute() {
      if (!this.run?.bpId) {
        return {};
      }
      return {
        name: "BP instance",
        params: { bpId: this.run.bpId },
      };
    },

    envRoute() {
      if (!this.run?.parentFileEnvId && !this.run?.fileEnvId) {
        return {};
      }
      return {
        name: "File env",
        params: {
          fileEnvId: this.run.fileEnvIsLocal
            ? this.run.parentFileEnvId
            : this.run.fileEnvId,
        },
      };
    },

    createdByRoute() {
      if (!this.run?.createdBy) {
        return {};
      }
      return {
        name: "User",
        params: { userId: this.run.createdBy },
      };
    },

    canCancel() {
      const status = this.run?.status;

      return (
        this.$store.state.runCreatePermission &&
        (status == "WAITING_FOR_DATA" ||
          status == "READY_TO_START" ||
          status == "WAITING_FOR_EVALUATION" ||
          status == "REJECTED_DATA" ||
          status == "INVALID_DATA" ||
          status == "COMPUTING" ||
          status == "INITIALIZING" ||
          status == "SCHEDULED" ||
          status == "RUNNING")
      );
    },

    canStart() {
      return (
        this.$store.state.runCreatePermission &&
        this.run?.status === "READY_TO_START" &&
        this.run?.requestedStatus !== "RUN_REQUESTED"
      );
    },

    canExportOlp() {
      return (
        this.$store.state.olpExportPermission && this.run?.status !== "INIT"
      );
    },

    cardLeftItem() {
      return {
        module: this.run?.module + " (v." + this.run?.moduleVersion + ")",
        simulation: this.run?.simulation,
        task: this.run?.taskVersionName
          ? this.run?.taskVersionName +
            " (v." +
            this.run?.taskVersionVersionNumber +
            ")"
          : "",
        bpInstance: this.run?.bpTitle
          ? "#" + this.run?.bpId + ":" + this.run?.bpTitle
          : "",
      };
    },

    cardMiddleItem() {
      let status = this.run?.status;
      let currentTask =
        this.run?.currentTask === 0 ? 0 : this.run?.currentTask - 1;
      let numTasks = this.run.nrOfTasks;
      const runTime = this.run?.runTime;
      let runTimeFull = "";
      if (runTime) {
        runTimeFull = runTime + " " + this.$i18n.t("global.concepts.seconds");
      }
      const envLabel = this.run.fileEnvIsLocal
        ? this.run.parentFileEnvLabel
        : this.run.fileEnvLabel;
      const envColor = this.run.fileEnvIsLocal
        ? this.run.parentFileEnvColor
        : this.run.fileEnvColor;

      return {
        createdByName: this.run.createdBy,
        dateCreated: new Date(this.run.dateCreated).toLocaleString(),
        env: envLabel,
        envColor: envColor,
        isRunning: status === "COMPUTING",
        runtime: runTimeFull,
        status: status,
        progress: numTasks ? (100 * currentTask) / numTasks : "",
        progressText:
          currentTask || currentTask == 0 ? currentTask + "/" + numTasks : "",
        translatedStatus: this.run?.translatedStatus,
      };
    },

    cardRightHeaders() {
      if (this.run?.props) {
        return Object.keys(this.run.props).map((prop) => {
          return { text: prop, value: prop };
        });
      }
      return [];
    },

    cardRightItem() {
      const item = {};
      this.cardRightHeaders.forEach((header) => {
        item[header.value] = this.run.props[header.value];
      });

      return item;
    },
  },

  async created() {
    const runId = this.$route.params.runId;

    this.run = await this.getRun(runId);
    this.editingName = this.run?.title;
    this.continuouslyUpdateRun();
  },

  destroyed() {
    clearTimeout(this.timerId);
  },

  methods: {
    listRuns() {
      this.$router.push({ name: "Runs" });
    },

    onEditConfirm() {
      put(
        "/simulationRun/rename?runId=" +
          this.run?.id +
          "&name=" +
          this.editingName
      )
        .then((r) => {
          this.editingName = r;
          this.run.title = r;
          this.showEdit = false;
        })
        .catch((e) => {
          this.$store.commit("showError", e);
          this.showEdit = false;
        });
    },

    editRun() {
      this.showEdit = true;
    },

    async deleteRun() {
      this.deleting = true;
      await remove("/simulationRun?id=" + this.run.id)
        .then(() => {
          this.$router.push({ name: "Runs" });
          this.deleting = false;
        })
        .catch((error) => {
          this.onError(error);
          this.deleting = false;
        });
    },

    async getRun(id) {
      return await get("/simulationRun?id=" + id).catch((e) => this.onError(e));
    },

    async onCancelRun() {
      await post("/simulationRun/" + this.run.infoId + "/cancel").catch(
        (error) => this.onError(error)
      );
    },

    async onExportOlp() {
      this.$refs.downloadComponent.href =
        baseUrl + "/simulationRun/" + this.run.infoId + "/export-olp";
      this.$refs.downloadComponent.click();
    },

    async onStartRun() {
      await get("/simulationRun/run?id=" + this.run.id).catch((error) =>
        this.onError(error)
      );
      this.run = await this.getRun(this.run.id);
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    continuouslyUpdateRun() {
      this.timerId = window.setTimeout(async () => {
        if (this.run?.id) {
          if (!this.deleting) {
            this.run = await this.getRun(this.run.id);
          }
          if (!this.$store.getters.isStatusFinished(this.run?.status)) {
            this.continuouslyUpdateRun();
          }
        }
      }, 1800);
    },

    showRequestStatus(status, requestedStatus) {
      return (
        (this.$store.getters.isStatusRunning(status) ||
          status === "WAITING_FOR_DATA" ||
          status === "READY_TO_START") &&
        requestedStatus === "CANCEL_REQUESTED"
      );
    },

    copyRun() {
      this.$router.push({
        name: "Copy Run",
        params: { runId: this.$route.params.runId },
      });
    },
  },
};
</script>
