<template>
  <v-container>
    <v-autocomplete
      v-model="vertex.name"
      outlined
      :items="taskNames"
      clearable
      :label="$t('global.concepts.name')"
      @change="onChange"
    ></v-autocomplete>
  </v-container>
</template>

<script>
export default {
  name: "BpDefinitionVertex",
  props: {
    initVertex: {},
    taskNames: [],
  },
  data() {
    return {
      vertex: {},
    };
  },
  watch: {
    initVertex: function () {
      this.vertex = this.initVertex;
    },
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
  },
  created() {
    this.vertex = this.initVertex;
  },
};
</script>
