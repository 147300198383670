<template>
  <module-template :title="$t('global.info.info')"
    ><v-card
      ><v-card-title>{{ $t("global.info.system-info") }}</v-card-title
      ><v-card-text
        ><basic-card-horizontal
          :headers="systemInfoHeaders"
          :item="systemInfoItem"
          ><template #[`item.threadDump`]>
            <a color="blue" :href="threadDumpUrl" download>{{
              $t("global.action.download")
            }}</a></template
          ></basic-card-horizontal
        ></v-card-text
      ></v-card
    ><v-card class="mt-4"
      ><v-card-title>{{ $t("global.info.database-settings") }}</v-card-title
      ><v-card-text
        ><basic-card-horizontal
          :headers="dbHeaders"
          :item="dbItem"
        ></basic-card-horizontal></v-card-text
    ></v-card>
    <v-card class="mt-4"
      ><v-card-title>{{
        $t("global.info.environment-properties")
      }}</v-card-title
      ><v-card-text
        ><basic-card-horizontal
          :headers="envPropHeaders"
          :item="envPropItem"
        ></basic-card-horizontal></v-card-text
    ></v-card>
    <v-card class="mt-4"
      ><v-card-title>{{ $t("global.info.java-properties") }}</v-card-title
      ><v-card-text
        ><basic-card-horizontal
          :headers="javaPropHeaders"
          :item="javaPropItem"
        ></basic-card-horizontal></v-card-text
    ></v-card>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BasicCardHorizontal from "@/components/BasicCardHorizontal";
import { baseUrl } from "@/model/http-common";
import { get } from "@/model/api";

export default {
  name: "SystemInfo",

  components: {
    ModuleTemplate,
    BasicCardHorizontal,
  },

  data() {
    return {
      systemInfoHeaders: [],
      systemInfoItem: {},

      dbHeaders: [],
      dbItem: {},

      envPropHeaders: [],
      envPropItem: {},

      javaPropHeaders: [],
      javaPropItem: {},

      maxValueLength: 100,
    };
  },

  computed: {
    threadDumpUrl() {
      return `${baseUrl}/info/thread-dump`;
    },
  },

  async created() {
    const info = await get("/info").catch((e) => {
      this.$store.commit("showError", e);
    });

    this.systemInfoHeaders = [
      {
        text: this.$t("global.info.thread-dump"),
        value: "threadDump",
        class: "ooliba-font-color-blue",
      },

      {
        text: this.$t("global.info.server-version"),
        value: "serverVersion",
      },

      {
        text: this.$t("global.info.server-build-time"),
        value: "serverBuildTime",
      },

      {
        text: this.$t("global.info.engine-version"),
        value: "engineVersion",
      },

      {
        text: this.$t("global.info.host-name"),
        value: "hostName",
      },
    ];

    this.systemInfoItem = {
      serverVersion: info.serverVersion,
      serverBuildTime: info.serverBuildTime,
      engineVersion: info.engineVersion,
      hostName: info.hostName,
    };

    Object.entries(info.networkInterfaces).forEach(([key, value]) => {
      this.systemInfoHeaders.push({
        text: "- " + key,
        value: key,
      });

      this.systemInfoItem[key] = value.join(", ");
    });

    this.systemInfoHeaders.push(
      {
        text: this.$t("global.info.java-version"),
        value: "javaVersion",
      },
      {
        text: this.$t("global.info.max-heap-space"),
        value: "maxHeapSpace",
      },
      {
        text: this.$t("global.info.total-heap-space"),
        value: "totalHeapSpace",
      },
      {
        text: this.$t("global.info.number-active-threads"),
        value: "numberActiveThreads",
      },
      {
        text: this.$t("global.info.physical-memory-size"),
        value: "physicalMemorySize",
      },
      {
        text: this.$t("global.info.free-physical-memory-size"),
        value: "freePhysicalMemorySize",
      }
    );
    this.systemInfoItem.javaVersion = info.javaVersion;
    this.systemInfoItem.maxHeapSpace =
      info.maxHeapSpace + " " + this.$t("global.info.mb");
    this.systemInfoItem.totalHeapSpace =
      info.totalHeapSpace + " " + this.$t("global.info.mb");
    this.systemInfoItem.numberActiveThreads = info.numThreads;
    this.systemInfoItem.physicalMemorySize =
      info.memory + " " + this.$t("global.info.mb");
    this.systemInfoItem.freePhysicalMemorySize =
      info.freeMemory + " " + this.$t("global.info.mb");

    Object.entries(info.memoryPools).forEach(([key, value]) => {
      this.systemInfoHeaders.push({
        text: "- " + key,
        value: key,
      });

      this.systemInfoItem[key] = value;
    });

    this.systemInfoHeaders.push({
      text: this.$t("global.info.number-available-processors"),
      value: "numberAvailableProcessors",
    });
    this.systemInfoItem.numberAvailableProcessors = info.availableProcessors;

    this.dbHeaders = [
      {
        text: this.$t("global.info.url"),
        value: "url",
      },
      {
        text: this.$t("global.info.user"),
        value: "user",
      },
    ];
    this.dbItem = {
      url: info.dbUrl,
      user: info.dbUser,
    };

    Object.entries(info.envProps).forEach(([key, value]) => {
      this.envPropHeaders.push({
        text: key,
        value: key,
      });

      var croppedValue = value;
      if (croppedValue.length > this.maxValueLength) {
        croppedValue = croppedValue.substring(0, this.maxValueLength) + "...";
      }
      this.envPropItem[key] = croppedValue;
    });

    Object.entries(info.javaProps).forEach(([key, value]) => {
      this.javaPropHeaders.push({
        text: key,
        value: key,
      });

      var croppedValue = value;
      if (croppedValue.length > this.maxValueLength) {
        croppedValue = croppedValue.substring(0, this.maxValueLength) + "...";
      }
      this.javaPropItem[key] = croppedValue;
    });
  },
};
</script>
