<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form
          @submit.prevent="createBPInstance"
          class="mx-auto"
          v-model="valid"
        >
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="data.title"
                :label="$t('global.concepts.name') + '*'"
                :rules="notEmpty"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                :items="bpDefinitions"
                :label="$t('global.business-process.definition') + '*'"
                v-model="data.bpDefinition"
                :rules="notEmpty"
                outlined
                @change="onBpSelect($event)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                :items="fileEnvNames"
                :label="$t('global.environment.environment') + '*'"
                :rules="notEmpty"
                v-model="data.environment"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <PropertyForm
            v-if="propertyForm"
            :form-rows="propertyForm"
            @input="onFormInput($event)"
          />
          <v-row align="center">
            <v-col cols="3">
              <v-checkbox
                v-model="data.localBp"
                :label="$t('global.business-process.local-bp')"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <basic-switch
                v-model="showProperties"
                dense
                :label="$t('global.concepts.add-props')"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-textarea
                v-if="showProperties"
                :label="$t('global.property.properties')"
                v-model="data.propertiesText"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
          <form-buttons
            :canSubmit="valid"
            :confirmText="$t('global.action.create')"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import FormButtons from "@/components/FormButtons";
import { post, get } from "@/model/api";
import PropertyForm from "@/components/PropertyForm.vue";
import combineProperties from "@/model/properties";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import BasicSwitch from "@/components/BasicSwitch";

export default {
  name: "CreateBPInstance",
  components: {
    PropertyForm,
    ModuleTemplate,
    FormButtons,
    BasicSwitch,
  },
  data() {
    return {
      data: {
        title: "",
        bpDefinition: "",
        environment: "",
        localBp: false,
        propertiesText: "",
      },
      fileEnvNames: [],
      bpDefinitions: [],
      propertyForm: [],
      valid: false,
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],
      showProperties: false,
    };
  },

  computed: {
    formTitle() {
      return this.$t("global.header.create", [
        this.$t("global.business-process.bp-instance").toLowerCase(),
      ]);
    },
  },

  methods: {
    onCancel() {
      this.$router.push({ name: "BP instances" });
    },

    async createBPInstance() {
      const data = {
        title: this.data.title,
        bpDefinition: this.data.bpDefinition,
        environment: this.data.environment,
        localBp: this.data.localBp,
        propertiesText: combineProperties(
          this.data.propertiesText,
          this.data.propertyForm
        ),
      };
      await post("/businessProcessInstance/save", data)
        .then(() => {
          this.$router.push({ name: "BP instances" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    onFormInput(propertyForm) {
      this.data.propertyForm = propertyForm;
    },
    async getFileEnvs() {
      return await get("/file-env").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    async getBps() {
      return await get("/business-process").catch((error) =>
        this.$store.commit("showError", error)
      );
    },
    async getPropertyForm(bpName) {
      return await get("/business-process/" + bpName + "/property-form").catch(
        (error) => this.$store.commit("showError", error)
      );
    },
    async onBpSelect(bpName) {
      this.propertyForm = await this.getPropertyForm(bpName);
    },
  },
  async created() {
    const fileEnvs = await this.getFileEnvs();

    if (fileEnvs) {
      this.fileEnvNames = [];

      fileEnvs.forEach((element) => {
        this.fileEnvNames.push(element.name);
      });
    }
    const bpDefinitions = await this.getBps();

    if (bpDefinitions) {
      this.bpDefinitions = [];

      bpDefinitions.forEach((definition) => {
        this.bpDefinitions.push(definition.name);
      });
    }
  },
};
</script>
