<template>
  <module-template id="scrolltainer" :title="$t('global.concepts.log')">
    <v-row dense>
      <v-col cols="2">
        <auto-complete-filter
          :items="typeFilters"
          item-text="text"
          item-value="id"
          v-model="typeFilter"
          @change="onFilter"
          :label="$t('global.action-log.log-type')"
        ></auto-complete-filter
      ></v-col>

      <v-col cols="2">
        <auto-complete-filter
          :items="userNames"
          v-model="userFilter"
          @change="onFilter"
          :label="$t('global.concepts.sysuser')"
        ></auto-complete-filter
      ></v-col>

      <v-col style="min-width: 13%">
        <v-menu
          ref="startDateMenu"
          :close-on-content-click="false"
          v-model="startDateMenu"
          :return-value.sync="startDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="$t('global.action.start-date')"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate">
            <v-spacer></v-spacer>
            <v-btn @click="startDateMenu = false">
              {{ $t("global.action.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onDateSelect($refs.startDateMenu, startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col style="min-width: 13%">
        <v-menu
          ref="endDateMenu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          :return-value.sync="endDate"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              :label="$t('global.action.end-date')"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate">
            <v-spacer></v-spacer>
            <v-btn @click="endDateMenu = false">
              {{ $t("global.action.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onDateSelect($refs.endDateMenu, endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-spacer />

      <v-col cols="2">
        <v-text-field
          v-model="advFilter"
          :label="$t('global.action-log.advanced-filter')"
          @keyup.enter="onFilter"
          outlined
        ></v-text-field>
      </v-col>

      <v-spacer />

      <v-col cols="1">
        <v-btn class="mr-2" @click="onClear">{{
          $t("global.action.clear")
        }}</v-btn>
      </v-col>
    </v-row>

    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      :server-items-length="numItems"
      @options-changed="onOptionsChanged"
    >
    </ooliba-basic-table>
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { get } from "@/model/api";
import AutoCompleteFilter from "@/components/AutoCompleteFilter";

export default {
  name: "LogList",

  components: {
    ModuleTemplate,
    OolibaBasicTable,
    AutoCompleteFilter,
  },

  data() {
    return {
      busy: false,
      options: {},
      numItems: 0,
      headers: [
        {
          text: this.$t("global.concepts.origin"),
          value: "user",
          sortable: false,
        },
        {
          text: this.$t("global.concepts.sysdate"),
          value: "date",
          width: "170px",
          sortable: false,
        },
        {
          text: this.$t("global.concepts.action"),
          value: "action",
          sortable: false,
        },
      ],
      items: [],
      typeFilter: undefined,
      userFilter: undefined,
      advFilter: undefined,
      startDate: undefined,
      endDate: undefined,
      startDateMenu: false,
      endDateMenu: false,
      userNames: [],
      typeFilters: [
        {
          id: "login",
          text: this.$t("global.action-log.login"),
        },
        {
          id: "failed.login",
          text: this.$t("global.action-log.failed-login"),
        },
        {
          id: "fileEnv.create",
          text: this.$t("global.action-log.env-creation"),
        },
        {
          id: "fileEnv.delete",
          text: this.$t("global.action-log.env-deletion"),
        },
        {
          id: "fileEnv.modify",
          text: this.$t("global.action-log.env-update"),
        },
        {
          id: "role.create",
          text: this.$t("global.action-log.role-creation"),
        },
        {
          id: "role.delete",
          text: this.$t("global.action-log.role-deletion"),
        },
        {
          id: "role.modify",
          text: this.$t("global.action-log.role-update"),
        },
        {
          id: "vault.create",
          text: this.$t("global.action-log.vault-creation"),
        },
        {
          id: "vault.delete",
          text: this.$t("global.action-log.vault-deletion"),
        },
        {
          id: "vault.user.add",
          text: this.$t("global.action-log.vault-user-creation"),
        },
        {
          id: "vault.user.delete",
          text: this.$t("global.action-log.vault-user-deletion"),
        },
        {
          id: "vault.user.modify",
          text: this.$t("global.action-log.vault-user-update"),
        },
        {
          id: "user.create",
          text: this.$t("global.action-log.user-creation"),
        },
        {
          id: "user.delete",
          text: this.$t("global.action-log.user-deletion"),
        },
        {
          id: "user.modify",
          text: this.$t("global.action-log.user-update"),
        },
        {
          id: "prop.create",
          text: this.$t("global.action-log.prop-creation"),
        },
        {
          id: "prop.delete",
          text: this.$t("global.action-log.prop-deletion"),
        },
        {
          id: "prop.modify",
          text: this.$t("global.action-log.prop-update"),
        },
        {
          id: "run.delete",
          text: this.$t("global.action-log.run-deletion"),
        },

        {
          id: "bpInst.delete",
          text: this.$t("global.action-log.bp-inst-deletion"),
        },

        {
          id: "bpInst.lock",
          text: this.$t("global.action-log.bp-inst-locking"),
        },

        {
          id: "bpInst.unlock",
          text: this.$t("global.action-log.bp-inst-unlocking"),
        },

        {
          id: "file.delete",
          text: this.$t("global.action-log.file-deletion"),
        },
        {
          id: "bpDef.create",
          text: this.$t("global.action-log.bp-def-creation"),
        },
        {
          id: "bpDef.delete",
          text: this.$t("global.action-log.bp-def-deletion"),
        },
        {
          id: "bpDef.modify",
          text: this.$t("global.action-log.bp-def-update"),
        },
        {
          id: "olp.import",
          text: this.$t("global.action-log.olp-import"),
        },
        {
          id: "release.delete",
          text: this.$t("global.action-log.release-delete"),
        },
      ],
    };
  },

  methods: {
    onOptionsChanged(options) {
      this.options = options;
      this.onFilter();
    },

    getLogs() {
      var path =
        "/log?page=" +
        this.options.page +
        "&itemsPerPage=" +
        this.options.itemsPerPage;
      if (this.typeFilter) {
        path += "&actionId=" + this.typeFilter;
      }
      if (this.userFilter) {
        path += "&userName=" + this.userFilter;
      }
      if (this.startDate) {
        path += "&startDate=" + this.startDate;
      }
      if (this.endDate) {
        path += "&endDate=" + this.endDate;
      }
      if (this.advFilter) {
        path += "&args=" + this.advFilter;
      }
      return get(path).catch((e) => this.$store.commit("showError", e));
    },

    setItems(logs) {
      this.items = [];
      logs.forEach((log) => {
        let item = {};
        item.user = log.userName;
        item.date = new Date(log.logDate).toLocaleString();
        item.action = this.createActionMessage(
          log.actionId,
          log.args,
          log.userName
        );
        this.items.push(item);
      });
    },

    createActionMessage(type, args, user) {
      const argsJson = JSON.parse(args);
      switch (type) {
        case "fileEnv.create":
          return this.$t("global.action-log.created", [
            this.$t("global.concepts.file-environment"),
            argsJson[0],
          ]);
        case "fileEnv.modify":
          return this.$t("global.action-log.updated", [
            this.$t("global.concepts.file-environment"),
            argsJson[0],
          ]);
        case "fileEnv.delete":
          return this.$t("global.action-log.deleted", [
            this.$t("global.concepts.file-environment"),
            argsJson[0],
          ]);
        case "role.create":
          return this.$t("global.action-log.created", [
            this.$t("global.concepts.sysrole"),
            argsJson[0],
          ]);
        case "role.delete":
          return this.$t("global.action-log.deleted", [
            this.$t("global.concepts.sysrole"),
            argsJson[0],
          ]);
        case "role.modify":
          var msg = this.$t("global.action-log.updated", [
            this.$t("global.concepts.sysrole"),
            argsJson[0],
          ]);
          if (argsJson[1] != argsJson[2]) {
            msg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.concepts.description"),
                argsJson[1],
                argsJson[2],
              ]);
          }
          if (argsJson[3]) {
            msg +=
              " " + this.$t("global.action-log.new-permissions", [argsJson[3]]);
          }
          if (argsJson[4]) {
            msg +=
              " " +
              this.$t("global.action-log.deleted-permissions", [argsJson[4]]);
          }
          return msg;
        case "vault.create":
          return this.$t("global.action-log.created", [
            this.$t("global.concepts.sysvault"),
            argsJson[0],
          ]);
        case "vault.delete":
          return this.$t("global.action-log.deleted", [
            this.$t("global.concepts.sysvault"),
            argsJson[0],
          ]);
        case "vault.user.delete":
          return this.$t("global.action-log.vault-user-deleted", [
            argsJson[1],
            argsJson[0],
          ]);
        case "vault.user.add":
          if (argsJson[2] === "W") {
            return this.$t("global.action-log.vault-user-added-write", [
              argsJson[1],
              argsJson[0],
            ]);
          }
          return this.$t("global.action-log.vault-user-added", [
            argsJson[1],
            argsJson[0],
          ]);
        case "vault.user.modify":
          if (argsJson[2] === "W") {
            return this.$t("global.action-log.vault-user-modified-write", [
              argsJson[1],
              argsJson[0],
            ]);
          }
          return this.$t("global.action-log.vault-user-modified", [
            argsJson[1],
            argsJson[0],
          ]);
        case "user.create":
          return this.$t("global.action-log.created", [
            this.$t("global.concepts.sysuser"),
            argsJson[0],
          ]);
        case "user.delete":
          return this.$t("global.action-log.deleted", [
            this.$t("global.concepts.sysuser"),
            argsJson[0],
          ]);
        case "user.modify":
          var userModMsg = this.$t("global.action-log.updated", [
            this.$t("global.concepts.sysuser"),
            argsJson[0],
          ]);
          if (argsJson[1] === "T") {
            userModMsg += " " + this.$t("global.action-log.password-changed");
          }
          if (argsJson[2] !== argsJson[3]) {
            userModMsg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.administration.first-name"),
                argsJson[2],
                argsJson[3],
              ]);
          }
          if (argsJson[4] !== argsJson[5]) {
            userModMsg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.administration.last-name"),
                argsJson[4],
                argsJson[5],
              ]);
          }
          if (argsJson[6] !== argsJson[7]) {
            userModMsg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.administration.email"),
                argsJson[6],
                argsJson[7],
              ]);
          }
          if (argsJson[8] !== argsJson[9]) {
            if (argsJson[8] === "F") {
              userModMsg += " " + this.$t("global.action-log.account-locked");
            } else {
              userModMsg +=
                " " + this.$t("global.action-log.account-not-locked");
            }
          }
          if (argsJson[10] !== argsJson[11]) {
            if (argsJson[10] === "F") {
              userModMsg += " " + this.$t("global.action-log.account-expired");
            } else {
              userModMsg +=
                " " + this.$t("global.action-log.account-not-expired");
            }
          }
          if (argsJson[12] !== argsJson[13]) {
            if (argsJson[12] === "F") {
              userModMsg += " " + this.$t("global.action-log.password-expired");
            } else {
              userModMsg +=
                " " + this.$t("global.action-log.password-not-expired");
            }
          }
          if (argsJson[14] !== argsJson[15]) {
            if (argsJson[14] === "F") {
              userModMsg += " " + this.$t("global.action-log.account-enabled");
            } else {
              userModMsg +=
                " " + this.$t("global.action-log.account-not-enabled");
            }
          }
          if (argsJson[16]) {
            userModMsg +=
              " " + this.$t("global.action-log.new-roles", [argsJson[16]]);
          }
          if (argsJson[17]) {
            userModMsg +=
              " " + this.$t("global.action-log.deleted-roles", [argsJson[17]]);
          }
          return userModMsg;
        case "prop.create":
          return this.$t("global.action-log.property-created", [
            argsJson[0],
            argsJson[1],
          ]);
        case "prop.delete":
          return this.$t("global.action-log.property-deleted", [
            argsJson[0],
            argsJson[1],
          ]);
        case "prop.modify":
          var propMsg = this.$t("global.action-log.updated", [
            this.$t("global.property.property"),
            argsJson[0],
          ]);
          if (argsJson[0] !== argsJson[1]) {
            propMsg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.concepts.name"),
                argsJson[0],
                argsJson[1],
              ]);
          }
          if (argsJson[2] !== argsJson[3]) {
            propMsg +=
              " " +
              this.$t("global.action-log.changed-from-to", [
                this.$t("global.concepts.value"),
                argsJson[2],
                argsJson[3],
              ]);
          }
          return propMsg;
        case "login":
          return this.$t("global.action-log.logged-in", [user]);
        case "run.delete":
          var rdMsg = this.$t("global.action-log.run-deleted", [argsJson[1]]);
          if (argsJson[0]) {
            rdMsg +=
              " " + this.$t("global.concepts.id") + ': "' + argsJson[0] + '".';
          }
          if (argsJson[2]) {
            rdMsg +=
              " " +
              this.$t("global.concepts.status") +
              ': "' +
              argsJson[2] +
              '".';
          }
          if (argsJson[3]) {
            rdMsg +=
              " " +
              this.$t("global.environment.environment") +
              ': "' +
              argsJson[3] +
              '".';
          }
          if (argsJson[4]) {
            rdMsg +=
              " " +
              this.$t("global.property.properties") +
              ': "' +
              argsJson[4] +
              '".';
          }
          return rdMsg;
        case "bpInst.delete":
          var bpIMsg = this.$t("global.action-log.bp-inst-deleted", [
            argsJson[1],
          ]);
          if (argsJson[0]) {
            bpIMsg +=
              " " + this.$t("global.concepts.id") + ': "' + argsJson[0] + '".';
          }
          if (argsJson[2]) {
            bpIMsg +=
              " " +
              this.$t("global.environment.environment") +
              ': "' +
              argsJson[2] +
              '".';
          }
          if (argsJson[3]) {
            bpIMsg +=
              " " +
              this.$t("global.property.properties") +
              ': "' +
              argsJson[3] +
              '".';
          }
          return bpIMsg;

        case "bpInst.lock":
          return this.$t("global.action-log.bp-inst-locked", [argsJson[0]]);

        case "bpInst.unlock":
          return this.$t("global.action-log.bp-inst-unlocked", [argsJson[0]]);

        case "file.delete":
          var fdMsg = this.$t("global.action-log.deleted", [
            this.$t("global.concepts.file"),
            argsJson[0],
          ]);
          if (argsJson[1]) {
            fdMsg +=
              " " +
              this.$t("global.environment.environment") +
              ': "' +
              argsJson[1] +
              '".';
          }
          return fdMsg;
        case "bpDef.create":
          return this.$t("global.action-log.created", [
            this.$t("global.business-process.bp-definition"),
            argsJson[0],
          ]);
        case "bpDef.modify":
          return this.$t("global.action-log.updated", [
            this.$t("global.business-process.bp-definition"),
            argsJson[0],
          ]);
        case "bpDef.delete":
          return this.$t("global.action-log.deleted", [
            this.$t("global.business-process.bp-definition"),
            argsJson[0],
          ]);
        case "olp.import":
          if (argsJson.length < 2) {
            return this.$t("global.action-log.olp-imported-no-name");
          }
          return this.$t("global.action-log.olp-imported", [
            argsJson[0],
            argsJson[1],
          ]);
        case "release.delete":
          return this.$t("global.action-log.release-deleted", [
            argsJson[0],
            argsJson[1],
          ]);
        case "failed.login":
          return this.$t("global.action-log.failed-login-user", [argsJson[0]]);
      }
    },

    onDateSelect(menu, date) {
      menu.save(date);
      if (this.startDate || this.endDate) {
        this.onFilter();
      }
    },

    async onFilter() {
      this.busy = true;
      const logs = await this.getLogs();
      if (logs) {
        this.setItems(logs.content);
        this.numItems = logs.totalElements;
      }
      this.busy = false;
    },

    onClear() {
      this.typeFilter = "";
      this.userFilter = "";
      this.startDate = "";
      this.endDate = "";
      this.advFilter = "";
      this.onFilter();
    },

    async getUsers() {
      return await get("/user/usernames").catch((e) => {
        this.$store.commit("showError", e);
      });
    },
  },

  async created() {
    this.busy = true;
    this.userNames = await this.getUsers();
    this.userNames?.push("System");
    this.busy = false;
  },
};
</script>
