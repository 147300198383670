<template>
  <div>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.username"
          :label="$t('global.administration.user-name') + '*'"
          :disabled="isEdit"
          :rules="notEmpty"
          @input="onInput()"
          outlined
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.password"
          :label="$t('global.pswDialog.psw') + (isEdit ? '' : '*')"
          type="password"
          :rules="isEdit ? [() => true] : notEmpty"
          @input="onInput()"
          outlined
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.firstName"
          :label="$t('global.administration.first-name')"
          @input="onInput()"
          outlined
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          v-model="formData.lastName"
          :label="$t('global.administration.last-name')"
          @input="onInput()"
          outlined
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col cols="6">
        <v-text-field
          type="email"
          v-model="formData.email"
          :label="$t('global.administration.email') + '*'"
          :rules="emailValid"
          @input="onInput()"
          outlined
        >
        </v-text-field> </v-col
    ></v-row>
    <v-row
      ><v-col>
        <v-checkbox
          :label="$t('global.administration.account-locked')"
          @change="onInput($event, option)"
          v-model="formData.accountLocked"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.account-expired')"
          @change="onInput($event, option)"
          v-model="formData.accounteExpired"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.password-expired')"
          @change="onInput($event, option)"
          v-model="formData.passwordExpired"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          :label="$t('global.administration.account-enabled')"
          @change="onInput($event, option)"
          v-model="formData.enabled"
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UserDetailsForm",
  props: {
    isEdit: Boolean,
    initialValues: Object,
  },
  data() {
    return {
      formData: {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        accountLocked: false,
        accounteExpired: false,
        passwordExpired: false,
        enabled: true,
      },
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],
      emailValid: [
        (v) => !!v || this.$t("global.rules.mandatory"),
        (v) => /.+@.+/.test(v) || this.$t("global.rules.email-valid"),
      ],
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.formData);
    },
  },
  created() {
    if (this.isEdit && this.initialValues) this.formData = this.initialValues;
  },
};
</script>
