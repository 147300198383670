<template>
  <div>
    <h6 class="py-4">
      {{ taskHeader }}
    </h6>
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      @row-clicked="onRowClick"
    >
      <template #[`item.status`]="{ item }">
        <template>
          <basic-tooltip
            :color="item.statusColor"
            :icon="item.statusIcon"
            :text="item.translatedStatus"
          />
        </template>
      </template>
    </ooliba-basic-table>
  </div>
</template>

<script>
import { get } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import BasicTooltip from "@/components/BasicTooltip";

export default {
  name: "TaskRunList",
  props: { flowTaskId: Number },
  components: { OolibaBasicTable, BasicTooltip },
  data() {
    return {
      runs: [],
      headers: [
        { text: "Id", value: "id" },
        { text: this.$t("global.concepts.name"), value: "title" },
        { text: this.$t("global.concepts.created-on"), value: "available" },
        { text: this.$t("global.modules.module"), value: "module" },
        { text: this.$t("global.concepts.status"), value: "status" },
      ],
      items: [],
      busy: true,
    };
  },

  computed: {
    taskHeader() {
      return this.$t("global.business-process.run-history");
    },
  },

  methods: {
    onRowClick(item) {
      const runId = item.id;

      this.$router.push({ name: "Run", params: { runId: runId } });
    },

    async getRuns(flowTaskId) {
      return await get("/flow-task/" + flowTaskId + "/runs").catch((error) => {
        this.onError(error);
      });
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    async loadRuns() {
      this.busy = true;
      this.items = [];
      this.runs = await this.getRuns(this.flowTaskId);

      if (this.runs) {
        this.runs.forEach(async (value) => {
          const item = {};

          item[this.headers[0].value] = value.id;
          item[this.headers[1].value] = value.title;
          item[this.headers[2].value] = new Date(
            value.dateCreated
          ).toLocaleString();
          item[this.headers[3].value] = value.module;
          item[this.headers[4].value] = value.status;

          let statusIcon = this.$store.getters.getStatusIcon(value.status);
          item.statusColor = statusIcon.statusColor;
          item.statusIcon = statusIcon.statusIcon;
          item.translatedStatus = value.translatedStatus;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
  },
  watch: {
    flowTaskId: async function () {
      await this.loadRuns();
    },
  },
  async created() {
    await this.loadRuns();
  },
};
</script>
