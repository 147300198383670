<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="submit" class="mx-auto" v-model="valid">
          <user-details-form
            v-if="userDetails"
            v-model="userDetails"
            :initialValues="userDetails"
            :isEdit="isEdit"
          />

          <h6 class="py-4">
            {{ $t("global.menu.administration.roles") }}
          </h6>

          <check-box-group
            v-if="selectedRoles && allRoles"
            v-model="selectedRoles"
            :initSelected="selectedRoles"
            :allOptions="allRoles"
            editable
          />
          <form-buttons
            :canSubmit="valid"
            :confirmText="
              isEdit ? $t('global.action.edit') : $t('global.action.create')
            "
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { post, get, put } from "@/model/api";
import UserDetailsForm from "@/components/security/UserDetailsForm";
import CheckBoxGroup from "@/components/CheckBoxGroup";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FormButtons from "@/components/FormButtons";

export default {
  name: "CreateEditUser",
  components: {
    ModuleTemplate,
    UserDetailsForm,
    CheckBoxGroup,
    FormButtons,
  },
  props: {
    isEdit: Boolean,
    userId: String,
    cancelRoute: String,
  },
  computed: {
    formTitle() {
      if (this.isEdit) {
        return this.$t("global.header.edit", [
          this.$t("global.concepts.sysuser").toLowerCase(),
        ]);
      }
      return this.$t("global.header.create", [
        this.$t("global.concepts.sysuser").toLowerCase(),
      ]);
    },
  },
  data() {
    return {
      userDetails: undefined,
      selectedRoles: undefined,
      allRoles: undefined,

      isBusy: true,
      valid: false,
    };
  },
  methods: {
    onCancel() {
      this.$router.push({ name: this.cancelRoute });
    },

    async submit() {
      this.userDetails.roles = this.selectedRoles;

      if (this.isEdit) await this.editUser(this.userDetails);
      else await this.createUser(this.userDetails);
    },

    async createUser(data) {
      return await post("/user/save", data)
        .then(() => {
          this.$router.push({ name: "Users" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    createUniqueConstraintMsg(e) {
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "email") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.concepts.sysuser"),
            this.$t("global.administration.email"),
            e.arguments[1],
          ]);
        }
        if (e.arguments[0] === "username") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.concepts.sysuser"),
            this.$t("global.administration.user-name"),
            e.arguments[1],
          ]);
        }
      }
      return e;
    },

    async editUser(data) {
      return await put("/user/" + this.userId, data)
        .then(() => {
          this.$router.push({ name: "User", params: { userId: this.userId } });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },
    async getAllRoles() {
      return await get("/role")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getRolesForuser(userId) {
      return await get("/user/" + userId + "/roles")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getUser(userId) {
      return await get("/user/" + userId)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
  },
  async created() {
    const roles = await this.getAllRoles();
    this.allRoles = [];
    roles?.forEach((role) => this.allRoles.push(role.name));

    if (this.isEdit) {
      this.userDetails = await this.getUser(this.userId);

      this.selectedRoles = [];
      const rolesForUser = await this.getRolesForuser(this.userId);
      rolesForUser?.forEach((role) => this.selectedRoles.push(role.name));
    } else {
      this.selectedRoles = [];
      this.userDetails = {};
    }
    this.busy = false;
  },
};
</script>
