<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.files')"
    :canCreate="$store.state.repoEntityCreatePermission"
    :createToolTip="
      $t('global.header.new', [$t('global.concepts.file').toLowerCase()])
    "
    :can-delete="$store.state.fileDeletePermission && selectedRows.length > 0"
    :delete-message="deleteMsg"
    @newItem="create"
    @deleteItem="onDelete"
  >
    <ooliba-basic-table
      v-model="selectedRows"
      @row-clicked="onRowClick"
      :headers="headers"
      :items="items"
      :loading="busy"
      :show-select="$store.state.fileDeletePermission"
      no-pagination
      slotted-left-panel
    >
      <v-autocomplete
        class="px-2"
        :items="fileEnvNames"
        :label="$t('global.environment.environment')"
        @change="onFileEnvSelect"
        v-model="selectedFileEnv"
        outlined
      ></v-autocomplete>
      <v-treeview
        :items="[folderTree]"
        :active.sync="treeActive"
        :open="treeOpen"
        active-class="ooliba-basic-table-tree-item-active"
        activatable
        dense
        hoverable
        transition
      >
        <template #prepend="{ open }">
          <v-icon small>
            {{ open ? "pic-folder-opened-fill" : "pic-folder-fill" }}
          </v-icon>
        </template>
        <template #label="{ item, active }">
          <v-chip small v-if="active" class="px-1 tree-chip">{{
            item?.name
          }}</v-chip>
          <span v-else>{{ item?.name }}</span></template
        >
      </v-treeview>
      <template #[`item.action`]="{ item }">
        <v-icon @click.stop="onDownload(item.id)">pic-import</v-icon>
      </template>
    </ooliba-basic-table>
    <v-dialog v-model="showUpload" width="500">
      <upload-file
        :uploading="uploading"
        @upload="onUpload($event)"
        @cancel="onCancel"
      ></upload-file>
    </v-dialog>
    <v-dialog v-model="showWarnings" width="500">
      <file-upload-warnings
        @cancel="onConfirmCancel"
        @confirmUpload="onConfirmUpload"
        :uploading="uploading"
        :warnings="warnings"
      ></file-upload-warnings>
    </v-dialog>
    <a ref="downloadComponent" download target="_blank" />
  </module-template>
</template>

<script>
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { get, remove, postForm } from "@/model/api";

import UploadFile from "@/components/UploadFile";
import { baseUrl } from "@/model/http-common";
import FileUploadWarnings from "@/components/run/FileUploadWarnings";

export default {
  name: "FileList",

  components: {
    ModuleTemplate,
    OolibaBasicTable,
    UploadFile,
    FileUploadWarnings,
  },

  data() {
    return {
      busy: true,
      selectedFileEnv: "default",
      folderTree: {},
      files: [],
      fileEnvNames: [],
      treeActive: [],
      treeOpen: [],
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.modules.latest-version"), value: "version" },
        { text: this.$t("global.concepts.last-updated"), value: "parent" },
        {
          text: this.$t("global.action.download"),
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      items: [],

      selectedRows: [],

      showWarnings: false,
      warnings: [],
      uploadParameters: {},
      showUpload: false,
      uploading: false,
    };
  },

  watch: {
    treeActive() {
      this.busy = true;
      this.updateFiles(this.treeActive[0]);
      this.busy = false;
    },
  },

  computed: {
    deleteMsg() {
      return this.$t("global.file.confirm-delete");
    },
  },

  methods: {
    async getFolderTree(env) {
      return await get("/file/folder?envName=" + encodeURIComponent(env)).catch(
        (err) => this.$store.commit("showError", err)
      );
    },

    onDownload(id) {
      const href = baseUrl + "/file/download-latest-version?id=" + id;

      this.$refs.downloadComponent.href = href;
      this.$refs.downloadComponent.click();
    },

    async onDelete() {
      this.busy = true;

      const ids = this.selectedRows.map((item) => item.id);
      remove("/file", { ids: ids })
        .then(() => {
          // We have to do a full update here instead of an updateFiles because if all files in a directory are deleted the directory is deleted as well so we can't access it anymore
          this.update();
        })
        .catch((e) =>
          this.$store.commit(
            "showError",
            this.$t("global." + e.message, e.arguments)
          )
        );
      this.selectedRows = [];

      this.busy = false;
    },

    onFileEnvSelect(newEvent) {
      this.selectedFileEnv = newEvent;
      this.busy = true;
      this.update();
      this.busy = false;
    },

    async getFiles(folderId) {
      return await get("/file/folder-files/" + folderId).catch((err) =>
        this.$store.commit("showError", err)
      );
    },

    onRowClick(row) {
      this.$router.push({ name: "File", params: { fileId: row.id } });
    },

    create() {
      this.showUpload = true;
    },

    async updateFiles(folderId) {
      if (!folderId) {
        return;
      }
      this.files = await this.getFiles(folderId);

      this.items = [];

      if (this.files) {
        for (let file of this.files) {
          const item = {};

          item.id = file.id;
          item[this.headers[0].value] = file.name;
          item[this.headers[1].value] = file.latestVersion;
          item[this.headers[2].value] = new Date(
            file.lastUpdated
          ).toLocaleString();

          this.items.push(item);
        }
      }
    },

    async update() {
      if (this.selectedFileEnv) {
        this.folderTree = await this.getFolderTree(this.selectedFileEnv);
      }
      if (this.folderTree) {
        this.treeActive = [this.folderTree.id];
        this.treeOpen = [this.folderTree.id];
      }
    },

    onCancel() {
      this.showUpload = false;
    },

    async onUpload(uploadParameters, force) {
      this.uploading = true;
      const formData = new FormData();

      this.uploadParameters.fileName = uploadParameters.fileName;
      this.uploadParameters.environment = uploadParameters.environment;
      this.uploadParameters.file = uploadParameters.file;
      this.uploadParameters.comment = uploadParameters.comment;

      formData.append("fileName", uploadParameters.fileName);
      formData.append("environment", uploadParameters.environment);
      formData.append("file", uploadParameters.file);
      formData.append("force", force);
      formData.append("comment", uploadParameters.comment);

      await postForm("/file/upload", formData)
        .then((res) => {
          this.uploading = false;
          this.uploaded(res);
        })
        .catch((error) => {
          this.uploading = false;
          this.$store.commit("showError", this.createErrorMsg(error));
        });
    },

    createErrorMsg(e) {
      if (e.message !== "unique.constraint" || !e.arguments) {
        return e;
      }
      if (e.arguments[0] === "name") {
        return this.$t("global.msg.already-exists", [
          this.$t("global.concepts.file"),
          this.$t("global.concepts.name").toLowerCase(),
          e.arguments[1],
        ]);
      }
      return this.$t("global." + e.message, e.arguments);
    },

    async uploaded(res) {
      if (res.messages?.length > 0) {
        this.showUpload = false;
        this.showWarnings = true;
        this.warnings = res.messages;
      } else {
        this.busy = true;
        this.showUpload = false;
        this.showWarnings = false;
        this.selectedFileEnv = res.file.fileEnv.name;
        if (this.selectedFileEnv) {
          this.folderTree = await this.getFolderTree(this.selectedFileEnv);
        }
        if (this.folderTree) {
          this.treeActive = [res.file.folder.id];

          let allParents = [];
          let folder = res.file.folder;
          while (folder.parent) {
            allParents.push(folder.parent.id);
            folder = folder.parent;
          }
          this.treeOpen = allParents;
        }
        this.busy = false;
      }
    },

    onConfirmCancel() {
      this.showWarnings = false;
    },

    onConfirmUpload() {
      if (this.uploadParameters) {
        this.onUpload(this.uploadParameters, true);
      }
    },
  },

  async created() {
    this.busy = true;
    this.fileEnvNames = await get("/file-env/names").catch((err) =>
      this.$store.commit("showError", err)
    );

    this.update();
    this.busy = false;
  },
};
</script>
