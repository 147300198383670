<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="create" class="mx-auto" v-model="valid">
          <process-task-form-fields
            :init-values="initFlowTaskData"
            @input="onInput($event)"
          ></process-task-form-fields>
          <form-buttons
            :canSubmit="valid"
            :confirmText="$t('global.action.create')"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import FormButtons from "@/components/FormButtons";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { post } from "@/model/api";
import ProcessTaskFormFields from "@/components/businessProcess/ProcessTaskFormFields.vue";

export default {
  name: "CreateProcessTask",
  components: {
    ProcessTaskFormFields,
    ModuleTemplate,
    FormButtons,
  },
  data() {
    return {
      flowTaskData: {},
      initFlowTaskData: {
        autoPublish: true,
        startRun: true,
      },
      valid: false,
    };
  },

  computed: {
    formTitle() {
      return this.$t("global.header.create", [
        this.$t("global.business-process.task").toLowerCase(),
      ]);
    },
  },

  methods: {
    onCancel() {
      this.$router.push({ name: "Process tasks" });
    },

    onInput(data) {
      this.flowTaskData = data;
    },
    async create() {
      var data = { ...this.flowTaskData };

      if (this.flowTaskData.responsibles)
        data.responsibles = this.flowTaskData.responsibles.join(";");

      await post("/flow-task-config/save", data)
        .then(() => {
          this.$router.push({ name: "Process tasks" });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
  },
};
</script>
