<i18n>
{
  "en":
  {
    "local" :
    {
      "showPassword" : "Show password",
      "hidePasword" : "Hide password",
      "rules":
      {
        "ruleContainsCharLowerCase": "The password must contain at least one lower case character",
        "ruleContainsCharUpperCase": "The password must contain at least one upper case character",
        "ruleContainsCharNumeric": "The password must contain at least one numeric character",
        "ruleContainsCharSpecial": "The password must contain at least one special character",
        "ruleMinimumLength8Chars": "The password must be at least 8 characters long"
      }
    }
  },
  "fr":
  {
    "local" :
    {
      "showPassword" : "Afficher le mot de passe",
      "hidePasword" : "Dissimuler le mot de passe",
      "rules":
      {
        "ruleContainsCharLowerCase": "Le mot de passe doit contenir au moins une lettre en minuscule",
        "ruleContainsCharUpperCase": "Le mot de passe doit contenir au moins une lettre en majuscule",
        "ruleContainsCharNumeric": "Le mot de passe doit contenir au moins un nombre",
        "ruleContainsCharSpecial": "Le mot de passe doit contenir au moins un caractère spécial",
        "ruleMinimumLength8Chars": "Le mot de passe doit contenir au moins 8 caractères"
      }
    }
  }
}
</i18n>
<template>
  <v-text-field
    ref="effisoftText"
    :id="id"
    :autocomplete="autocomplete"
    :counter="counter && value.length > 0"
    :disabled="disabled"
    :label="label"
    :outlined="outlined"
    :placeholder="placeholder"
    :required="required"
    :rules="localRules"
    :type="inputType"
    :value="value"
    persistent-placeholder
    @input="emitInput"
  >
    <template #append-outer>
      <v-icon
        class="pt-5"
        :disabled="tooglePasswordDisabled"
        :title="tooglePasswordLabel"
        color="grey"
        tabindex="-1"
        @click="tooglePasswordVisibility"
        >{{ tooglePasswordIcon }}</v-icon
      >
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "compliance-password",
  props: {
    autocomplete: {
      type: String,
      default: "off",
    },
    // indique s'il faut afficher le nombre de caractères saisis
    counter: {
      type: Boolean,
      required: false,
      default: false,
    },
    // indique si le champ est en lecture seule
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
    },
    // libellé du champ
    label: {
      type: String,
      required: false,
      default: "",
    },
    // v-text-field outlined prop
    outlined: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: " ",
    },
    // indique si le champ est obligatoire
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Règles de validation pour remplacer celles par défaut
    // C'est un tableau de fonctions qui reçoivent la valeur à contrôler et renvoient :
    // - true si la valeur est correcte
    // - une chaîne de caractère (message d'erreur) si la valeur est fausse
    rules: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * Bind to a variable if the rules depends on the variable.
     * This will relaunch the rules when the variable depend on changed
     */
    rulesDependOn: {
      type: [Object, String, Number, Array],
    },
    // Valeur du champ
    // Peut être liée dans les deux sens avec v-model
    value: {
      type: String,
    },
  },
  data() {
    return {
      localRules:
        // règles obligatoires
        [this.ruleRequired].concat(
          // remplacement des règles facultatives
          this.rules || [
            // règles facultatives
            this.ruleContainsCharUpperCase,
            this.ruleContainsCharLowerCase,
            this.ruleContainsCharNumeric,
            this.ruleContainsCharSpecial,
            this.ruleMinimumLength8Chars,
          ]
        ),
      localValue: this.value,
      showPassword: false,
    };
  },

  computed: {
    inputType() {
      return this.showPassword ? "text" : "password";
    },
    tooglePasswordDisabled() {
      return this.localValue === null || this.localValue === "";
    },
    tooglePasswordIcon() {
      return this.showPassword ? "pic-view-hide" : "pic-view";
    },
    tooglePasswordLabel() {
      return this.$t(
        this.showPassword ? "local.hidePasword" : "local.showPassword"
      );
    },
  },
  watch: {
    rulesDependOn: {
      deep: true,
      handler() {
        if (this.$refs.effisoftText) {
          this.$refs.effisoftText.validate();
          if (this.$refs.effisoftText.errorBucket.length) {
            this.$refs.effisoftText.hasInput = true;
          }
        }
      },
    },

    value(newVal) {
      this.localValue = newVal;
    },
  },
  methods: {
    emitInput(newValue) {
      this.localValue = newValue;
      this.$emit("input", newValue);
    },
    ruleContainsCharUpperCase(v) {
      return (
        v === "" ||
        (v && v.replace(/[^A-Z]/g, "").length >= 1) ||
        this.$i18n.t("local.rules.ruleContainsCharUpperCase")
      );
    },
    ruleContainsCharLowerCase(v) {
      return (
        v === "" ||
        (v && v.replace(/[^a-zàâäçéèêëîïôöùûüÿ]/g, "").length >= 1) ||
        this.$i18n.t("local.rules.ruleContainsCharLowerCase")
      );
    },
    ruleContainsCharNumeric(v) {
      return (
        v === "" ||
        (v && v.replace(/[^0-9]/g, "").length >= 1) ||
        this.$i18n.t("local.rules.ruleContainsCharNumeric")
      );
    },
    ruleContainsCharSpecial(v) {
      return (
        v === "" ||
        (v && v.replace(/[^!#$%&'()*+,-./:;<=>?@^_`{|}~]/g, "").length >= 1) ||
        this.$i18n.t("local.rules.ruleContainsCharSpecial")
      );
    },
    ruleMinimumLength8Chars(v) {
      return (
        v === "" ||
        (v && v.length >= 8) ||
        this.$i18n.t("local.rules.ruleMinimumLength8Chars")
      );
    },
    ruleRequired(v) {
      return (
        this.disabled ||
        !this.required ||
        (v && v !== "") ||
        this.$t("global.rules.mandatory")
      );
    },
    tooglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
