<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.business-process.bp-definitions')"
    :canCreate="$store.state.bpDefCreatePermission"
    :createToolTip="
      $t('global.header.new', [
        $t('global.business-process.bp-definition').toLowerCase(),
      ])
    "
    canImport
    @newItem="create"
    @import="onImport"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      sort-by="name"
      no-pagination
      @row-clicked="onRowClick"
    />
    <v-dialog v-model="showImport" width="500">
      <v-overlay absolute :value="uploading">
        <v-chip color="blue" dark>{{ $t("global.msg.importing") }}</v-chip>
      </v-overlay>
      <v-card>
        <v-card-title>{{ $t("global.business-process.import") }}</v-card-title>
        <v-form
          @submit.prevent="onImportConfirm"
          class="mx-auto"
          v-model="valid"
        >
          <v-card-text>
            <v-file-input
              show-size
              :label="$t('global.concepts.choose-file')"
              accept=".olp"
              :rules="notEmpty"
              v-model="file"
              outlined
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
            <v-btn color="primary" :disabled="!valid" type="submit">{{
              $t("global.modules.import")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </module-template>
</template>

<script>
import { get, postForm } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";

export default {
  name: "BPDefinitionList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
  },
  data() {
    return {
      headers: [{ text: this.$t("global.concepts.name"), value: "name" }],
      items: [],
      bps: [],
      showImport: false,
      file: undefined,
      valid: false,
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],

      busy: true,
      uploading: false,
    };
  },
  methods: {
    create() {
      this.$router.push({ name: "Create BP definition" });
    },

    onImport() {
      this.showImport = true;
    },

    onCancel() {
      this.showImport = false;
      this.file = undefined;
    },

    onImportConfirm() {
      this.uploading = true;
      const formData = new FormData();

      formData.append("olpFile", this.file);

      postForm("/business-process/import", formData)
        .then((res) => {
          this.showImport = false;
          this.loadData(res);
          this.uploading = false;
        })
        .catch((e) => {
          this.onError(e);
          this.uploading = false;
        });
      this.file = undefined;
    },

    async getBps() {
      return await get("/business-process").catch((error) =>
        this.onError(error)
      );
    },
    onError(error) {
      if (error.message == "bpDef.import-failed") {
        this.$store.commit("showError", this.$t("global.msg.import-failed"));
      } else {
        this.$store.commit("showError", error);
      }
    },
    onRowClick(record) {
      this.$router.push({
        name: "BP definition",
        params: { bpId: record.id },
      });
    },

    async loadData() {
      this.busy = true;
      this.items = [];
      this.bps = await this.getBps();

      if (this.bps) {
        this.bps.forEach((value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] = value.name;

          this.items.push(item);
        });
      }
      this.busy = false;
    },
  },

  created() {
    this.loadData();
  },
};
</script>
