<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <h5>
            {{ name }}
          </h5>
        </v-col>
        <v-spacer />
        <v-col>
          <v-autocomplete
            :items="envs"
            :label="$t('global.environment.environment')"
            v-model="selectedEnv"
            @input="getFileVersions(name, selectedEnv)"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>

      <ooliba-basic-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :selected.sync="selectedFile"
        item-key="versionNumber"
        no-pagination
        @row-clicked="onRowClick"
      >
        <template #[`item.dateCreated`]="{ item }">
          {{ new Date(item.dateCreated).toLocaleString() }}
        </template>
      </ooliba-basic-table>

      <v-row dense justify="end">
        <v-col cols="auto">
          <v-btn @click="onCancel">
            {{ $t("global.action.cancel") }}
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="onPickVersion"
            :disabled="!selectedFile"
          >
            {{ selectedBtnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { get } from "@/model/api";

export default {
  components: {
    OolibaBasicTable,
  },

  props: {
    name: String,
    env: String,
    value: Boolean,
  },

  data() {
    return {
      headers: [
        { value: "versionNumber", text: this.$t("global.concepts.version") },
        { value: "dateCreated", text: this.$t("global.concepts.created-on") },
        { value: "createdBy", text: this.$t("global.concepts.created-by") },
        { value: "fileSize", text: this.$t("global.concepts.size") },
      ],
      items: [],
      envs: [],
      loading: false,
      selectedFile: null,
      selectedEnv: null,
    };
  },

  computed: {
    selectedVersion() {
      if (this.selectedFile === null) {
        return "";
      }
      return this.selectedFile.versionNumber;
    },

    selectedBtnText() {
      return `${this.$t("global.action.pick-version")} ${this.selectedVersion}`;
    },

    triggerLoad() {
      return {
        name: this.name,
        isShow: this.value,
      };
    },
  },

  watch: {
    /**
     * Launch load when isShow && name
     */
    triggerLoad: {
      deep: true,
      immediate: true,
      handler() {
        if (this.triggerLoad.name && this.triggerLoad.isShow) {
          this.getFileVersions(this.name, this.env);
          this.getFileEnvs();
          this.selectedEnv = this.env;
        }
      },
    },
  },

  methods: {
    onRowClick(clickedRow) {
      this.selectedFile = clickedRow;
    },

    async getFileVersions(name, env) {
      this.loading = true;
      this.items = [];

      const path = `/file/versions?fileName=${encodeURIComponent(
        name
      )}&environment=${encodeURIComponent(env)}`;
      await get(path)
        .then((data) => {
          this.items = this.getItems(data);
          this.loading = false;
        })
        .catch((err) => this.$store.commit("showError", err));

      return this.items;
    },

    async getFileEnvs() {
      this.loading = true;
      const path = `/file-env/names`;

      await get(path)
        .then((data) => {
          this.envs = data;
          if (!this.envs.includes(this.env)) {
            this.envs.unshift(this.env);
          }
          this.loading = false;
        })
        .catch((err) => this.$store.commit("showError", err));
    },

    getItems(files) {
      let items = [];

      files.forEach((value) => {
        let item = {};

        item.id = value.id;
        item.versionNumber = value.versionNumber;
        item.dateCreated = value.dateCreated;
        item.createdBy = value.createdBy;
        item.fileSize = `${Math.round(value.fileSize / 1000)} KB`;

        items.push(item);
      });
      return items;
    },

    onCancel() {
      this.selectedFile = null;
      this.$emit("cancel");
      this.$emit("input", false);
    },

    onPickVersion() {
      this.$emit("pick-version", this.selectedFile.id);
      this.selectedFile = null;
      this.$emit("input", false);
    },
  },
};
</script>
