<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form
          @submit.prevent="createBPDefinition"
          class="mx-auto"
          v-model="valid"
        >
          <bp-definition-form-fields
            :init-values="data"
            @input="onInput($event)"
          ></bp-definition-form-fields>
          <form-buttons
            :canSubmit="valid"
            :confirmText="$t('global.action.create')"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import FormButtons from "@/components/FormButtons";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { post, get } from "@/model/api";
import { createInvalidGraphError } from "@/model/util";
import BpDefinitionFormFields from "@/components/businessProcess/bpDefinition/BpDefinitionFormFields";

export default {
  name: "CreateBPDefinition",
  components: {
    ModuleTemplate,
    BpDefinitionFormFields,
    FormButtons,
  },
  data() {
    return {
      defaultForm: undefined,
      data: {
        name: "",
        description: "",
        form: "",
        jsonGraph: "",
      },
      valid: false,
    };
  },

  computed: {
    formTitle() {
      return this.$t("global.header.create", [
        this.$t("global.business-process.bp-definition").toLowerCase(),
      ]);
    },
  },

  methods: {
    onCancel() {
      this.$router.push({ name: "BP definitions" });
    },

    onInput(data) {
      this.data = data;
    },
    onError(error) {
      this.$store.commit("showError", error);
    },
    async getDefaultBpForm() {
      return await get("/environment-property/default-bp-form").catch(
        (error) => {
          this.onError(error);
        }
      );
    },

    handleCreateError(e) {
      if (!e.message) {
        return e;
      }
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "name") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.business-process.bp-definition"),
            this.$t("global.concepts.name"),
            e.arguments[1],
          ]);
        }
      }
      return createInvalidGraphError(e);
    },

    async createBPDefinition() {
      const data = {
        name: this.data.name,
        jsonGraph: this.data.jsonGraph,
        form: this.data.form,
        description: this.data.description,
      };
      await post("/business-process/save", data)
        .then(() => {
          this.$router.push({ name: "BP definitions" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.handleCreateError(e));
        });
    },
  },
  async created() {
    this.data.form = JSON.stringify(await this.getDefaultBpForm(), null, 2);
  },
};
</script>
