<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="submit" class="mx-auto" v-model="valid">
          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="name"
                :label="$t('global.concepts.name') + '*'"
                :disabled="isEdit"
                :rules="notEmpty"
                outlined
              >
              </v-text-field> </v-col
          ></v-row>

          <v-row
            ><v-col cols="6">
              <v-text-field
                v-model="description"
                :label="$t('global.concepts.description')"
                outlined
              >
              </v-text-field> </v-col
          ></v-row>

          <h6 class="py-4">
            {{ $t("global.administration.permissions") }}
          </h6>

          <v-expansion-panels multiple v-model="panel" accordion>
            <v-expansion-panel
              v-for="catPermission in categoryPermissions"
              :key="catPermission.category"
            >
              <v-expansion-panel-header
                color="#f2f2f2"
                class="font-weight-bold"
              >
                {{ catPermission.category }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="2"
                    ><v-checkbox
                      hide-details
                      dense
                      :label="$t('global.concepts.check-all')"
                      @change="
                        toggleSelectAll($event, catPermission.permissions)
                      "
                      :input-value="areAllSelected(catPermission.permissions)"
                    >
                    </v-checkbox
                  ></v-col>
                  <v-col
                    v-for="perm in catPermission.permissions"
                    :key="perm.name"
                    cols="2"
                  >
                    <v-checkbox
                      hide-details
                      dense
                      :label="perm.translated"
                      :value="perm.name"
                      v-model="selectedPermissions"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <form-buttons
            :confirmText="
              isEdit ? $t('global.action.edit') : $t('global.action.create')
            "
            @cancel="onCancel"
            :canSubmit="valid"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { post, get, put } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FormButtons from "@/components/FormButtons";

export default {
  name: "CreateEditRole",
  components: {
    ModuleTemplate,
    FormButtons,
  },
  props: {
    isEdit: Boolean,
    roleId: String,
    cancelRoute: String,
  },
  computed: {
    formTitle() {
      if (this.isEdit) {
        return this.$t("global.header.edit", [
          this.$t("global.concepts.sysrole").toLowerCase(),
        ]);
      }
      return this.$t("global.header.create", [
        this.$t("global.concepts.sysrole").toLowerCase(),
      ]);
    },
  },
  data() {
    return {
      name: "",
      description: "",
      selectedPermissions: [],
      categoryPermissions: [],
      valid: false,
      panel: [],
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],
    };
  },
  methods: {
    areAllSelected(permissions) {
      return permissions.every((perm) =>
        this.selectedPermissions.includes(perm.name)
      );
    },

    toggleSelectAll(event, permissions) {
      if (event) {
        permissions.forEach((perm) => {
          if (!this.selectedPermissions.includes(perm.name)) {
            this.selectedPermissions.push(perm.name);
          }
        });
      } else {
        permissions.forEach((perm) => {
          this.selectedPermissions = this.selectedPermissions.filter(
            (el) => el !== perm.name
          );
        });
      }
    },

    onCancel() {
      this.$router.push({ name: this.cancelRoute });
    },

    async submit() {
      const data = {
        name: this.name,
        description: this.description,
        permissions: this.selectedPermissions,
      };
      if (this.isEdit) await this.editRole(data);
      else await this.createRole(data);
    },

    createUniqueConstraintMsg(e) {
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "name") {
          return this.$t("global.msg.already-exists", [
            this.$t("global.concepts.sysrole"),
            this.$t("global.concepts.name"),
            e.arguments[1],
          ]);
        }
      }
      return e;
    },

    async createRole(data) {
      return await post("/role/save", data)
        .then(() => {
          this.$router.push({ name: "Roles" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    async editRole(data) {
      return await put("/role/" + this.roleId, data)
        .then(() => {
          this.$router.push({ name: "Role" });
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    async getCategoryPermissions() {
      return await get("/permission").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async getPermissionsForRole(roleId) {
      return await get("/role/" + roleId + "/permissions").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    async getRole(id) {
      return await get("/role/" + id).catch((error) => {
        this.$store.commit("showError", error);
      });
    },
  },
  async created() {
    this.categoryPermissions = await this.getCategoryPermissions();
    this.panel = [...Array(this.categoryPermissions.length).keys()];

    if (this.isEdit) {
      const role = await this.getRole(this.roleId);

      this.name = role.name;
      this.description = role.description;
      this.selectedPermissions = await this.getPermissionsForRole(role.id);
    }
  },
};
</script>
