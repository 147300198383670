<template>
  <v-chip :color="colorHex" dark small><slot></slot></v-chip>
</template>

<script>
export default {
  name: "FileEnvChip",

  props: {
    color: String,
  },

  computed: {
    colorHex() {
      switch (this.color) {
        case "GREEN":
          return "#468847";
        case "ORANGE":
          return "#cc6216";
        case "RED":
          return "#b94a48";
        case "BLUE":
          return "#3a87ad";
        case "BLACK":
          return "#333";
        default:
          return "#999";
      }
    },
  },
};
</script>
