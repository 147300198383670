<template>
  <v-autocomplete
    :items="items"
    v-model="selection"
    @change="$emit('change', $event)"
    outlined
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    :label="label"
    chips
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :close="close"
        @click:close="
          selection = undefined;
          $emit('change', $event);
        "
      >
        <span
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          {{ data.item.text ?? data.item }}
        </span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AutoCompleteFilter",
  props: {
    value: undefined,
    items: Array,
    disabled: Boolean,
    label: String,
    close: { Boolean, default: true },
    itemText: String,
    itemValue: String,
  },
  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
