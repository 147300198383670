<template>
  <v-container>
    <v-autocomplete
      :items="vertices"
      outlined
      clearable
      :label="$t('global.concepts.from')"
      v-model="edge.from"
      @change="onChange"
    ></v-autocomplete>
    <v-autocomplete
      :items="vertices"
      outlined
      clearable
      :label="$t('global.concepts.to')"
      v-model="edge.to"
      @change="onChange"
    ></v-autocomplete>
    <v-checkbox
      v-model="edge.blockOnWarning"
      :label="$t('global.business-process.block-warning')"
      dense
      color="primary"
      @change="onChange"
    ></v-checkbox>
  </v-container>
</template>

<script>
export default {
  name: "BpDefintionEdge",
  props: {
    initEdge: {},
    vertices: [],
  },
  data() {
    return {
      edge: {},
    };
  },
  watch: {
    initEdge: function () {
      this.edge = this.initEdge;
    },
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
  },
  created() {
    this.edge = this.initEdge;
  },
};
</script>
