<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-form @submit.prevent="addEditUser" class="mx-auto" v-model="valid">
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="users"
              :label="$t('global.concepts.sysuser') + '*'"
              :rules="notEmpty"
              :disabled="isEdit"
              v-model="user"
              outlined
            ></v-autocomplete></v-col
        ></v-row>
        <v-checkbox
          :label="$t('global.administration.write-permission')"
          v-model="writePermission"
          :input-value="true"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onCancel">{{ $t("global.action.cancel") }}</v-btn>
        <v-btn type="submit" :disabled="!valid" color="primary">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { post, put, get } from "@/model/api";

export default {
  name: "AddEditVaultUser",
  components: {},
  props: {
    vaultId: Number,
    isEdit: Boolean,
    initUser: String,
    initWritePermission: Boolean,
  },
  computed: {
    buttonText() {
      return this.isEdit
        ? this.$t("global.concepts.update")
        : this.$t("global.administration.add-user");
    },

    title() {
      return this.isEdit
        ? this.$t("global.administration.edit-user")
        : this.$t("global.administration.add-user");
    },
  },
  data() {
    return {
      user: "",
      users: [],
      writePermission: "false",
      valid: false,
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],
    };
  },
  methods: {
    onCancel(event) {
      this.$emit("cancel", event);
    },

    async addEditUser() {
      const data = {
        username: this.user,
        writePermission: this.writePermission,
      };
      if (this.isEdit) await this.editUser(data);
      else await this.addUser(data);
    },

    async addUser(data) {
      await post("/vault/" + this.vaultId + "/user", data)
        .then(() => {
          this.$emit("done");
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    async editUser(data) {
      await put("/vault/" + this.vaultId + "/user", data)
        .then(() => {
          this.$emit("done");
        })
        .catch((e) => {
          this.$store.commit("showError", this.createUniqueConstraintMsg(e));
        });
    },

    createUniqueConstraintMsg(e) {
      if (e.message === "unique.constraint" && e.arguments) {
        if (e.arguments[0] === "name") {
          return this.$t("global.msg.already-access", [e.arguments[1]]);
        }
      }
      return e;
    },

    async getUsers() {
      return await get("/user/usernames").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
  },

  async created() {
    this.user = this.initUser;
    this.users = await this.getUsers();
    this.writePermission = this.initWritePermission;
  },
};
</script>
