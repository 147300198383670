const http = require("./http-common.js").default;

import i18n from "@/i18n";
import store from "@/store";

export function get(path, config) {
  return http
    .get(path, config)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export function post(path, data, config) {
  return http
    .post(path, data, config)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export async function downloadFile(url, onError, onSuccess) {
  var config = {
    responseType: "blob",
  };
  return http
    .get(url, config)
    .then((response) => {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;

      // Get filename from axios response
      const fileField = "filename=";
      let hearderContent = response.headers["content-disposition"].split(";");
      let filenameField = hearderContent.find(
        (field) => field && field.includes(fileField)
      );
      let filename = filenameField
        .replace(fileField, "")
        .replaceAll('"', "")
        .trim();

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      if (onSuccess) {
        onSuccess();
      }
      fileLink.click();
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

export function put(path, data) {
  return http
    .put(path, data)
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

export function postForm(url, data) {
  var config = {
    headers: {
      "Content-type": "multipart/form-data",
    },
  };
  return post(url, data, config);
}

export function remove(path, data) {
  return http
    .delete(path, { data: data })
    .then((response) => response.data)
    .catch((ex) => onError(ex));
}

const onError = (ex) => {
  if (ex.response.status === 403) {
    store.commit("clear");
  }
  if (ex.response.status === 429) {
    ex.response.data = i18n.t("global.msg.error-upload-limit");
  }
  return new Promise(() => {
    throw ex.response.data;
  });
};
