<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.file-environments')"
    :canCreate="this.$store.state.fileEnvCreatePermission"
    :createToolTip="
      $t('global.header.new', [
        $t('global.concepts.file-environment').toLowerCase(),
      ])
    "
    @newItem="create"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      sort-by="name"
      no-pagination
      @row-clicked="onRowClick"
      ><template v-slot:[`item.label`]="{ item }">
        <file-env-chip :color="item.color"> {{ item.label }} </file-env-chip>
      </template></ooliba-basic-table
    >
  </module-template>
</template>

<script>
import { get } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import FileEnvChip from "@/components/FileEnvChip";

export default {
  name: "FileEnvList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
    FileEnvChip,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.environment.label"), value: "label" },
        { text: this.$t("global.concepts.parent"), value: "parent" },
        { text: this.$t("global.administration.vault"), value: "vault" },
      ],
      items: [],
      fileEnvs: [],

      busy: true,
    };
  },
  methods: {
    create() {
      this.$router.push({ name: "Create file env" });
    },

    async getFileEnvs() {
      return await get("/file-env").catch((error) => {
        this.$store.commit("showError", error);
      });
    },

    onRowClick(record) {
      const fileEnvId = record.id;

      this.$router.push({ name: "File env", params: { fileEnvId: fileEnvId } });
    },
  },
  async created() {
    this.fileEnvs = await this.getFileEnvs();

    this.fileEnvs.forEach((value) => {
      const item = {};

      item.id = value.id;
      item[this.headers[0].value] = value.name;
      item[this.headers[1].value] = value.label;
      item[this.headers[2].value] = value.parent === "" ? "-" : value.parent;
      item[this.headers[3].value] = value.vault;
      item.color = value.color;

      this.items.push(item);
    });
    this.busy = false;
  },
};
</script>
