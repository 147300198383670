<template>
  <div id="the-account-menu">
    <compliance-menu
      :label="$store.state.username"
      :dark-label="darkLabel"
      icon="pic-user"
    >
      <compliance-menu-item
        :label="$t('global.action.disconnect')"
        isList
        @click="logout"
      />
      <compliance-menu-item
        :label="$t('global.pswDialog.changePsw')"
        isList
        @click="changePassword"
      />
    </compliance-menu>

    <compliance-dialog
      v-model="showChangePsw"
      :title="$t('global.pswDialog.psw')"
    >
      <template #default>
        <v-form v-model="isValid" ref="form" autocomplete="off">
          <compliance-password
            v-model.trim="oldPsw"
            :label="$t('global.pswDialog.oldPsw')"
            :rules="oldPswRules"
            counter
            id="oldPswId"
            ref="oldPsw"
            required
          />
          <compliance-password
            v-model.trim="newPsw"
            :label="$t('global.pswDialog.newPsw')"
            counter
            id="newPswId"
            ref="newPsw"
            required
          />
          <compliance-password-confirm
            v-model.trim="confirmPsw"
            :password="newPsw"
            id="confirmPswId"
            ref="confirmPsw"
            required
          />
        </v-form>
      </template>
      <template #actions>
        <v-btn depressed :disabled="!isValid" color="primary" @click="submit">{{
          $t("global.pswDialog.submit")
        }}</v-btn>
      </template>
    </compliance-dialog>
  </div>
</template>

<script>
import { post, put } from "@/model/api";
import ComplianceMenu from "@/components/ComplianceMenu";
import ComplianceMenuItem from "@/components/ComplianceMenuItem";
import ComplianceDialog from "@/components/ComplianceDialog";
import CompliancePassword from "@/components/CompliancePassword";
import CompliancePasswordConfirm from "@/components/CompliancePasswordConfirm";

export default {
  name: "AccountMenu",

  props: {
    darkLabel: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ComplianceMenu,
    ComplianceMenuItem,
    ComplianceDialog,
    CompliancePassword,
    CompliancePasswordConfirm,
  },
  data() {
    return {
      showChangePsw: false,
      isValid: false,
      oldPsw: "",
      oldPswRules: [],
      newPsw: "",
      confirmPsw: "",
    };
  },
  methods: {
    logout() {
      post("/authenticate/logout")
        .then(() => {
          this.$store.commit("clear");
        })
        .catch((ex) => {
          this.$store.commit("showError", ex);
          this.$store.commit("clear");
        });
    },

    submit() {
      if (this.$refs.form.validate()) {
        let payload = {
          oldPassword: this.oldPsw,
          newPassword: this.newPsw,
        };
        put("/user/" + this.$store.state.username + "/change-password", payload)
          .then(() => {
            this.$store.commit(
              "showSuccess",
              this.$t("global.pswDialog.pswChangedMsg")
            );
            this.close();
          })
          .catch(() => {
            this.$store.commit(
              "showError",
              this.$t("global.pswDialog.pswNotChangedMsg")
            );
          });
      }
    },

    close() {
      this.showChangePsw = false;
      this.oldPsw = "";
      this.newPsw = "";
      this.confirmPsw = "";
    },

    changePassword() {
      this.showChangePsw = true;
    },
  },
};
</script>
