<template>
  <module-template id="scrolltainer" :title="formTitle">
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="createRun" class="mx-auto" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                :items="fileEnvNames"
                :label="$t('global.environment.environment') + '*'"
                :rules="notEmpty"
                v-model="fileEnv"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <create-run-form-fields
            v-if="loaded"
            show-form
            :is-copy="!!runToCopy"
            :init-values="initRunData"
            :init-properties="properties"
            @input="onInput($event)"
          ></create-run-form-fields>
          <form-buttons
            :canSubmit="valid"
            :confirmText="$t('global.action.create')"
            @cancel="onCancel"
          ></form-buttons>
        </v-form>
      </v-card-text>
    </v-card>
  </module-template>
</template>

<script>
import { post, get } from "@/model/api";
import CreateRunFormFields from "@/components/run/CreateRunFormFields.vue";
import combineProperties from "@/model/properties";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import FormButtons from "@/components/FormButtons";

export default {
  name: "CreateRunForm",
  components: {
    CreateRunFormFields,
    ModuleTemplate,
    FormButtons,
  },
  props: {
    runToCopy: {},
  },
  data() {
    return {
      fileEnv: "",
      createRunData: {},
      fileEnvNames: [],
      properties: "",
      loaded: false,
      initRunData: {},
      notEmpty: [(v) => !!v || this.$t("global.rules.mandatory")],
      valid: false,
    };
  },
  computed: {
    formTitle() {
      if (this.runToCopy) {
        return this.$t("global.header.copy", [
          this.$t("global.run.run").toLowerCase(),
        ]);
      }
      return this.$t("global.header.create", [
        this.$t("global.run.run").toLowerCase(),
      ]);
    },
  },
  methods: {
    onInput(data) {
      this.createRunData = data;
    },

    onCancel() {
      this.$router.push({
        name: this.runToCopy ? "Run" : "Runs",
        params: this.runToCopy ? "{ runId: runId }" : "",
      });
    },

    async createRun() {
      const data = {
        fileEnv: this.fileEnv,
        module: this.createRunData.module,
        moduleVersion: this.createRunData.moduleVersion,
        simulation: this.createRunData.simulation,
        title: this.createRunData.title,
        propertiesText: combineProperties(
          this.createRunData.propertiesText,
          this.createRunData.propertyForm
        ),
        copySimulationRunId: this.runToCopy?.id ?? -1,
        autoPublish: this.createRunData.autoPublish,
        startRun: this.createRunData.startRun,
        keepFileVersions: this.createRunData.keepFileVersions,
      };
      await post("/simulationRun/create", data)
        .then((response) => {
          this.$router.push({ name: "Run", params: { runId: response.id } });
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    async getFileEnvs() {
      return await get("/file-env").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
  },
  async created() {
    const fileEnvs = await this.getFileEnvs();

    if (fileEnvs) {
      this.fileEnvNames = [];

      fileEnvs.forEach((element) => {
        this.fileEnvNames.push(element.name);
      });
    }
    if (this.runToCopy) {
      this.initRunData = {};
      this.fileEnv = this.runToCopy.fileEnvName;
      if (!this.fileEnvNames.includes(this.fileEnv)) {
        this.fileEnvNames.unshift(this.fileEnv);
      }
      this.$set(this.initRunData, "module", this.runToCopy.module);
      this.$set(
        this.initRunData,
        "moduleVersion",
        this.runToCopy.moduleVersion
      );
      this.$set(this.initRunData, "simulation", this.runToCopy.simulation);
      this.$set(this.initRunData, "title", this.runToCopy.title + "_COPY");
      this.$set(this.initRunData, "autoPublish", this.runToCopy.autoPublish);
      this.properties = this.runToCopy.propertiesText;
    }
    this.loaded = true;
  },
};
</script>
