import i18n from "../i18n";

export function createInvalidGraphError(e) {
  switch (e.message) {
    case "CYCLE_DETECTED":
      return i18n.t("global.business-process.cycle-detected");
    case "DUPLICATE_VERTEX_ID":
      return i18n.t("global.business-process.duplicate-vertex-id", [
        e.arguments[0],
      ]);
    case "DUPLICATE_EDGE_ID":
      return i18n.t("global.business-process.duplicate-edge-id", [
        e.arguments[0],
      ]);
    case "INVALID_EDGE_VERTEX_REF":
      return i18n.t("global.business-process.invalid-edge-vertex-ref", [
        e.arguments[0],
        e.arguments[1],
      ]);
    case "MISSING_EDGE_ID":
      return i18n.t("global.business-process.missing-edge-id");
    case "MISSING_VERTEX_NAME":
      return i18n.t("global.business-process.missing-vertex-name", [
        e.arguments[0],
      ]);
    case "MISSING_VERTEX_ID":
      return i18n.t("global.business-process.missing-vertex-id");
    case "MISSING_EDGE_FROM":
      return i18n.t("global.business-process.missing-edge-from", [
        e.arguments[0],
      ]);
    case "MISSING_EDGE_TO":
      return i18n.t("global.business-process.missing-edge-to", [
        e.arguments[0],
      ]);
    case "NO_VERTICES":
      return i18n.t("global.business-process.no-vertices");
    default:
      return e;
  }
}
